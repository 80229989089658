<template>
<div class="menuBarItem" @click="this.$emit('change-page',this.dataItemName)">
     <text class="menuItemText">{{menuItemName}}</text>
</div>
</template>

<script>
export default {
    name: 'MenuBarItem',
    props: {
        itemName: String, 
    },
    emits:['change-page'],
    data() {
        return {
            menuItemName: "",
        }
    },
    created() {
        switch(this.itemName) {
            case "ServicesPage":
                this.menuItemName = "Services";
                this.dataItemName = "ServicesPage"
                break;
            case "ContactUs":
                this.menuItemName = "Contact";
                this.dataItemName = "ContactUs"
                break;
            case "PortfolioPage":
                this.menuItemName = "Portfolio";
                this.dataItemName = "PortfolioPage"
                break;
            case "HomePage":
                this.menuItemName = "Home";
                this.dataItemName = "HomePage"
        }
        this.buttonName = this.itemName
    },
}
</script>
<style>
.menuBarItem {
    font-family: "Bebas_Neue variant0", Tofu;
    font-variant: small-caps;
    font-size: xx-large;
    cursor:pointer;
    margin-top:27px;
    margin-bottom:27px;
    border-radius: 10px;
    background-color:rgb(113,153,169,.5);
}
.menuItemText {
    margin:15px 15px 15px 15px;
}
</style>
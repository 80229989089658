<template>
    <div class="menuBarContainer">
        <MenuBarItem class="menuFlexStart" itemName="HomePage" @change-page="myClick"/>
        <MenuBarItem itemName="ServicesPage" @change-page="myClick"/>
        <MenuBarItem itemName="PortfolioPage" @change-page="myClick"/>
        <MenuBarItem itemName="ContactUs" @change-page="myClick"/>
    </div>
</template>

<script>
import MenuBarItem from './MenuBarItem.vue';

export default {
    name: 'MenuBar',
    components:{
        MenuBarItem
    },
    emits:['change-page'],
    methods: {
        myClick(page){
            page = page === "PortfolioPage" ? "ComingSoon" : page;
            this.$emit('change-page', page)
        },
    }
}
</script>
<style> 
.headerImage > img {
    max-width:25vw;
    flex:1;
}
.menuBarContainer {
    max-width:1250px;
    display:flex;
    gap:20px;
    align-items: center;
    justify-content: center;
    flex:1;
    border-radius: 10px;
}
</style>
<template>
    <div id="appBody">
        <Content :contentRequestedPage="this.requestedPage"/>
    </div>
</template>
<script>
import Content from './Content.vue';

export default {
    name:'AppBody',
    props: {
        requestedPageProp: String,
    },
    data() {
        return {
            requestedPage: this.requestedPageProp,
        }
    },
    setup(props) {        
        console.log(`AppBodySetup ${props.requestedPageProp}`);
        return(props);
    },
    watch: {
        requestedPageProp(newValue){
            this.requestedPage = newValue;
        },
    },
    components: {
        Content
    },
}
</script>
<style>
#appBody{
height:100%
}
#contentArea {
    max-width:1250px;
}
</style>
<template>
    <div class="homePage">
        <div class="pageTitle">
            <h2>Aerial Photography <br/> For Everyone</h2>
        </div> 
        <div class="pageContent">
            <div class="contentItem" @click="changeSubPage('RealEstate')">
                <h3>Real Estate</h3>
                <text class="rollOverText">RealEstate Text</text>
            </div>
            <div class="contentItem" @click="changeSubPage('HomeOwner')">
                <h3>Home Owner</h3>
                <text class="rollOverText">HMAR Text</text>
            </div>
            <div class="contentItem">
                <h3>Construction</h3>
                <text class="rollOverText">Construction Text</text>            
            </div>
            <div class="contentItem">
                <h3>Special Events</h3>
                <text class="rollOverText">Special Text</text>
            </div>
            <div class="contentItem">
                <h3>Personal Videography</h3>
                <text class="rollOverText">Personal Text</text>
            </div>
            <div class="contentItemEnd"></div>
        </div>        
        <component class="subContent" :is="this.subContent"/>

    </div>
</template>
<script>
import ComingSoon from './ComingSoon.vue';
import RealEstate from './industries/RealEstate.vue'
export default {
    name: 'HomePage',
    components: {
        RealEstate,
        ComingSoon,
    },
    data() {
        return {
            subContent: 'RealEstate',
        }
    },
    methods: {
        changeSubPage(page) {
            this.subContent = page;
        }
    }
}
</script>
<style>
.homePage {
    height: 100%;
    flex-direction: column;
}

.pageTitle {
    text-align:center;
    h2{
    color:#2a3135;}
}
.subContent
{
    max-width: 1000px;
}
.pageContent {
    display:inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width:200px;
}

.contentItem {
    flex:auto;
    width:180px;
    background-color: #7199a9;
    border:1px;
    border-style: hidden;
    border-radius: 10px;
    cursor:pointer;
    margin:10px;
    h3 {
        margin:5px 0px 5px 10px;
        font-variant: small-caps;
        font-size:x-large;
    }
    .contentImage {
       display:none;
    }
    .rollOverText {
        display:none;
    }
    :hover {
        img {
            display:none;
        }
    .rollOverText{
            display:block;
        }
    }
}

.contentItemEnd {
    display:block;
}

@media (width <= 775px) {
    .pageContent {
        flex-direction: column;
        text-align: center;
    }
    .contentItem {
        display:flex;
        justify-content:left;
        flex-direction: row-reverse;
        img {
            width: unset;
            max-height:50px;
        }
    }
    .contentItem:hover {
        img, .rollOverText{
            display:none;
        }        
    }

}
</style>
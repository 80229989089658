<template>
    <div class="contentWrapper">
        <div class="contentBuffer"></div>
        <component class="content" :is="this.currentComponent"/>
        <div class="contentBuffer"></div>
    </div>
</template>

<script>
import ComingSoon from './ComingSoon.vue';
import ContactUs from './ContactUs.vue';
import PortfolioPage from './PortfolioPage.vue';
import ServicesPage from './ServicesPage.vue'
import HomePage from './HomePage.vue';

export default {
    // eslint-disable-next-line
    name: 'Content',
    props: {
        contentRequestedPage: String,
    },
    components:{
        ComingSoon,
        ContactUs,
        PortfolioPage,
        ServicesPage,
        HomePage
    },
    data() {
        return {
            currentComponent: this.contentRequestedPage,
        }
    },
    watch: {
        contentRequestedPage(newValue){
            this.currentComponent = newValue;
        }
    },
    setup(props) {
        return(props)
    },
    methods: {
        switchComponent(newComponent){
            this.currentComponent = this.currentComponent === newComponent ? this.currentComponent : newComponent;
        },
    },
}
</script>
<style>
.contentWrapper {
    display:flex;
    justify-content: center;
    flex-direction: row;
    height:100%;
    width:100%;
}
.contentBuffer {
    display:none;
}
.content {
    flex:1;
    display:flex;
    margin-top:20px;
}
@media (width >= 1250px) {
    .contentBuffer{
        display:block;
        flex:1;        
    }
    .content{
        min-width: 1250px;
    }
}
</style>
<template>
    <div class="subContentPanel">
      <h2>Lorum Ipsum</h2>
    </div>
  </template>
  
<script>
export default {
name: 'RealEstate',
// Component logic (data, methods, etc.)
}
</script>

<style>
.subContentPanel {
    height:200px;
    border:solid;
    border-color: red;
}
</style>
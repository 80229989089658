<template>
    <div id="comingSoon">
        <h2 class="title"> Arundel Aerial Solutions </h2>
        <text class="subtext"> Your Low Altitude Aerial Solutions Provider </text>
        <h2 text-align:center> Coming Soon! </h2>
    </div>
</template>

<script>
export default {
  name: 'ComingSoon'
}
</script>

<style>
#comingSoon{
  flex-direction: column;
  text-align: center;
}
.title{
  font-family: "Bebas_Neue variant0", Tofu;
  font-variant: small-caps;
  font-size: xx-large;
}
.subtext {
  font-family: "Bebas_Neue variant0", Tofu;
  font-size: xx-large;

}

</style>
<template>    
      <div class="header_logo">
        <img class="watermarkImage" src="./assets/logo.png" onclick="window.location.reload()">
      </div>
      <div class='app_header'>      
        <div class="buffer"></div>
        <AppHeader @change-page="changePage"/>
        <div class="buffer"></div>        
      </div>
      <AppBody :requestedPageProp="this.currentPage"/>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppBody from './components/AppBody.vue';
export default {
  name: 'App',
  components: {
    AppHeader,
    AppBody,
  },
  data() {
    return {
      currentPage: "ComingSoon",
    }
  },
  methods: {
    changePage(page) {
      console.log(`appvue - ${page}`);
      this.currentPage = page;
    },
  },
}
</script>

<style>
#app {
  background-image: url('./assets/vlcsnap-2024-12-14-08h50m43s337.png');
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  font-family: "Bebas_Neue variant0", Tofu;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a3135;
  min-width:550px;
  height:100vh;
}

.app_header{
  display:flex;
}

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.buffer {
  flex:none;
}

.header_logo {
  position: absolute;
  top:0;
  left:0;
  cursor:pointer;
}

.watermarkImage {
  max-height: 100px;
}

@media (width >= 1250px) {
  .appContainer {
    max-width:1250px;
  }
  .buffer {
    flex:1;
  }
}
</style>

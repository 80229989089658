<template>
    <div class="contactUsWrapper">
        <div class="intro">
            <h2>Contact us for More Information</h2>
            <p>We look forward to hearing from you!</p>
        </div>
        <div class="formWrapper">
            <div class="formBuffer"></div>
            <div class="contactUsForm">
                <form v-bind:name="contactUs" v-on:submit=doSubmit>
                    <label for="name">Name</label>
                    <input class="formInput" id="name" type="text" required placeholder="Your Name: "/>

                    <label>E-Mail</label>
                    <input class="formInput" id="email" type="text" required placeholder="Email Address: "/>

                    <label>Organization</label>
                    <input class="formInput" id="org" type="text" placeholder="Organization: (optional) "/>

                    <label>Message</label>
                    <textarea class="formInput" id="message" type="text" required placeholder="Give us a brief description of your project"></textarea>
                    <button @click="doSubmit">Send</button>
                </form>                
            </div>
            <div class="formBuffer"></div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'ContactUs',
  methods: {
    doSubmit() {
        try {
            alert('Under Construction - Check back soon!');
        } catch (error) {
            console.error(error.message);
        }
    },
  }
}
</script>
<style>
    .contactUsWrapper {
        flex-direction: column;
        text-align: left;
        height:100%;
    }
    .formWrapper {
        display:flex;
        justify-content: center;
        flex-direction: row;
        -ms-flex-align: center;
    }

    .intro {
        color:white;
        h2,p{
            margin:0;
            text-align:center;
        }
    }

    .contactUsForm {        
        margin:20px;
        max-width:500px;
        flex:1;
    }

    .formBuffer {
        display:block;
        flex:1;
    }

    input[type=text], textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    }

    /* Style the submit button */
    input[type=submit] {
    width: 100%;
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    }
</style>
<template>
  <div class="appHeader">
    <div class="header_menuBar">
      <MenuBar @change-page="changePage"/>
    </div>
  </div>
</template>

<script>
import MenuBar from './MenuBar.vue';

export default {
  name: 'AppHeader',
  components: {
    MenuBar,
  },
  emits:['change-page'],
  methods: {
    changePage(page) {
      this.$emit('change-page',page);
    },
  }

}
</script>

<style>

.appHeader {
  display:flex;
  align-items: center;
  justify-content: center;
  flex:1;
}

.header_menuBar {
  flex:auto;
  max-width:1250px;
}


/* For mobile phones: */
@media (width >= 1250px) {
  .appHeader {
    min-width:1250px;
  }
}

@media (width <= 775px) {
  #appHeader {
    position: absolute;
    width:100%;
    height:125px;
    text-align: right;
  }
  #watermarkImage {
    position: relative;
    top:-250px;
    right:0px;
    max-height: 100px;
    opacity: 0.5;
  }
}

</style>
<template>    
    <div class="servicesPage">
        <div class="service">
            <h2>Aerial Video and Photography</h2>
            <p>Flexible packages to meet your needs!</p>
            <!--<ul>
                <li> 1 Hour ($85)</li>
                <ul>
                    <li>Includes digital delivery of raw video or image files</li>
                    <li>Optional physical media $15</li>
                </ul>
                <li> 3 hours ($225)</li>
                <ul>
                    <li>Includes Basic Production package</li>
                    <li>Optional physical media $15</li>
                </ul>            
                <li> 8 Hours ($499)</li>
                <ul>
                    <li>Includes Full Production package</li>
                    <li>Includes physical media</li>
                </ul>
            </ul>-->

            <p>Session times are for pilot Time On Site. The length and number of your final video(s) will vary based on flight time and and video captured.</p>
        </div>
        <div class="service">
            <h2>Video Production</h2>
            <p>We provide video files in 4k/60 with XX seconds of video. You can use these raw video files to meet your needs, or you can select from one of production packages.</p>
            <ul>
                <li>Basic ($49)</li>
                <ul>
                    <li>We'll stitch together all footage into one large continuous video file. </li>
                    <li>If you have audio you'd like included, we'll do that too. Licensing is on you.</li>
                </ul>
                <li>Full ($139)</li>
                <ul>
                    <li>2 hours of video editing and production.</li>
                    <li>Together, we'll pick the ideal shots for your final video(s).</li>
                    <li>Includes a rough draft for your review and approval</li>
                    <li>Includes two change request sessions.</li>
                    <li> Need to make some light changes or other tweaks before</li>
                </ul>
            </ul>
        </div>
        <div class="service">
            <h2>Flight Services</h2>
            <text>You buy, we'll fly.</text>
            <ol style="text-align:left;">
                <li>Contact us!</li>
                <ul>
                    <li>Give us a brief description of your project and needs.</li>
                    <li>Preferably this contact will include a desired timeframe and a geographic area.</li>
                </ul>
                <li>We'll contact you with more details!</li>
                <li>After you've selected a package and location, we'll assemble a flight operations plan.</li>
                <ul>
                    <li>Review weather conditions and forecasts. </li>
                    <li>Review and complete any FAA requirements for the operation.</li>
                </ul>
                <li>We fly!</li>
                <li>Production</li>
                    <ul>
                        <li>Hollywood Style - We just capture the footage; you've got your own editing team.</li>
                        <li>Choose one of our production packages above.</li>
                    </ul>
                <li>Delivery!</li>
                    <ul>
                        <li>If you've selected digital delivery we'll upload the raw captured footage, and any produced videos, to the provider of your choosing.</li>
                        <li>If you've also selected Physical Media, we'll also provide files on USB or MicroSD portable media.</li>
                    </ul>
                <li>We provide you the final video or image files.</li>
            </ol>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesPage',
}
</script>
<style>
.contentPage {
    background-image: url('../assets/vlcsnap-2024-12-14-11h07m46s451.png');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}
.servicesPage {
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: center;
    height: 100%;
    padding-top:10px;
}
.service
{
    width:1000px;
    height: fit-content;
    font-family: "Bebas_Neue variant0", Tofu;
    background-color: #7199a9;
    opacity: .9;
    font-variant: small-caps;
    border:1px,solid,black;
    border-radius: 20px;
    margin:10px 10px 10px 10px;
    padding: 5px;
    flex:1;
}
.service > h2 {
    text-align:center;
}

.service > p, ul {
    font-variant: normal;    
}

ul{display:none;}

.topService {
    background-color:orange;
    width:100%;
}

@media only screen and (max-width: 768px) {
    #servicesPage {
    text-align: left;
    display:block;;
}
}
</style>
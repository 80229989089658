<template>
    <div id="portfolioPage">

    </div>
</template>

<script>
export default {
    name: 'PortfolioPage',
}
</script>